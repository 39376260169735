.about{
    color: white;
    /* margin-top: 40px; */
    margin-left: 50px;
}
.body-text{
    margin-bottom: 10px;
    margin-top: -80px;
    font-style: oblique;
   
    margin-left: -40px;
}
.container {
    display: flex;
}

.body {
    flex: 1;
    padding: 10px;
    margin-top: 40px;
    margin-bottom: 40px;
}
.contactus{
    color: white;
   
    margin-left: 40px;
}


/* .body-text {
    border: 1px solid #000; 
    padding: 10px;
} */
.img {
    height: 570px;
    width: 100%;
}
.form-group {

margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}
input[type='email'],
input[type='text'],
input[type='tel'] {
  width: 450px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #636161;
  border-radius: 5px;
}

textarea {
    height: 80px; /* Adjust height as needed */
    width:450px;
    margin-left: 10px;
    padding: 10px;
    font-size: 16px;
  border: 1px solid #636161;
  border-radius: 5px;
  }
button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgb(0, 33, 65);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
}

button:hover {
  background-color: #0056b3;
 
}
@media(max-width:767px){
    input[type='email'],
input[type='text'],
input[type='tel']

 
{
  width: 285px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #636161;
  border-radius: 5px;
}
textarea {
    height: 80px; /* Adjust height as needed */
    width:285px;
    margin-left: 10px;
    padding: 10px;
    font-size: 16px;
  border: 1px solid #636161;
  border-radius: 5px;
  }
  .body-text {

 margin-bottom: 10px;
    margin-top: -2px;
    font-style: oblique;
    margin-left: 50px;
  }

}
