/* homeDecor.css */

.home-decors-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
    /* margin-right: 20px; */
    gap:35px;
    margin-bottom: 50px;
  }
 
  
  
  .paint-img {
    background-color: white;
    height: 350px; 
    width: 300px; 
    border: 0.5px solid #ccc;
    transition: transform 0.3s ease-in-out;
    border-radius: 15px; 
}

  
.paint-img img {
    padding: 0px;
    /* margin-top: 2px; */
    margin-left: 25px;
    width: 240px;
    height: 237px;
    object-fit: contain;
    /* border-top-left-radius: 53px; 
    border-top-right-radius: 48px;  */
    border-radius: 30px; 
    margin-bottom: 10px;
}

  
  .paint-img img:hover {
    transform: scale(1.1);
  }
  .paint-img button{
    background-color: rgb(12, 54, 78);
    border: none;
    padding: 5px;
    color: white;
    margin-left: 50px;
  }
  
  .white-house-pg {
    color: rgb(2, 2, 20);
    font-size: 24px;
    text-align: center; /* Center text */
    font-family: emoji; /* Change font */
    margin-top: -25px;
}
.jp-nagar {
    /* Your CSS properties here */
    color: rgb(17, 29, 51);
    font-size: 18px;
    text-align:left; /* Center text */
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 20px;
    font-family: math;
   
}
.jp-nagar .icon {
    color:rgb(3, 27, 51); 
    margin-right: 5px; 
    vertical-align: middle; 
    margin-right: 5px;
}

.rupee-amount {
   
    font-size: 18px;
    color: rgb(2, 19, 2);
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 60px;
    
}
.hre{
  color: black;
}

.rupee-amount .icon {
   
    color: red;
    margin-right: 5px; 
    vertical-align: middle;
    
}


.account-statement-container {
  background: #fff;
  height: auto;
  min-height: 100px;
  margin-bottom: 25px;
  padding: 50px 15px;
  /* border-radius: 5px; */
  /* position: relative; */
  /* box-shadow: 0px 0px 5px #1a89a1; */
  /* box-shadow: 5px #fff; */
  /* margin-top: 30px; */
}

.account-statement-title {
  font-size: 30px;
  color:rgb(3, 27, 51);
  margin-top: 30px;
  font-family: "Roboto", sans-serif;
}

.account-option-select {
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  width: 315px;
  border: 1px solid rgb(199, 205, 211);
  border-radius: 5px;
  /* font-style:oblique; */
}

.account-search-input {
  margin: 5px;
  padding: 5px;
  height: 30px;
  display: inline-block;
  position: relative;
  width: 200px;
  border: 0px solid #16b0c8;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

/* Media query for smaller screens */
@media (max-width: 767px) {
  .home-decors-cont {
    margin-right: 20px;
    margin-left: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px; /* Add gap between grid items */
  }

  .paint-img {
    height: 225px; 
    width: calc(106%); 
    max-width: 102%; 
   /* display: ; */
  }

  .paint-img img {
    height: 40%; 
    width: 100%; 
    border-radius: 15px; 
    margin-top: 10px;
   
    margin-left: 0px;
  }

  .white-house-pg,
  .jp-nagar,
  .rupee-amount {
    margin-left: 10px; /* Adjust margin for text elements */
    font-size: 15px;
    margin-top: 12px;
    
  }
}

@media screen and (max-width: 767px) {
  .account-option-select {
    font-size: 10px; 
    width: 87%; 
    margin-left: 22px;
  }
  .account-statement-title {
    margin-left: 22px;
    margin-top: 50px;
  }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
  .account-option-select {
    font-size: 14px;
    width: 200px; 
  }

}












