.footer-container{
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;

    background-color: rgb(3, 27, 51); 
     margin-bottom: 5px;
  height:300px;
   
}
.footer-container-data{
    margin-bottom: -15px;
    background-color: #f8f6f3;
}

.footer-container h3{
    color: rgb(249, 251, 253);
    margin-top: 29px;
  
}
.footer-container p{
  margin-top: x;
    color:rgb(168, 166, 166)
}

.paragrph{
    text-align: center;
}
.hr{
    color: rgb(249, 251, 253);
    font-size: 20px;
}
.h2{
    color: #7c7472;
    margin: 15px;
    margin-left: -0px;
}
@media screen and (max-width:768px){
    .footer-container{
        flex-direction: column;
        align-items: start;
        padding-left: 30px;
        justify-content:flex-start;
        height:100%;
       
    }
}
.icon{
    color: #f8f6f3;
    size-adjust: 20px;
    display: flex;
    height: 20px;
    width: 20px;
    margin-right: 20px;
} 
.img-src{
    height: 20px;
    width: 30px;
}
.nav__brand{
margin-top: 20px;
}








/* In pgowners.css */

.theme-links {
    display: flex;
    flex-direction: column;
   
  }
  
  .theme-links h3 {
    margin-bottom: 10px; 
  }
  
  .theme-links .hr {
    width: 100%; 
    margin-bottom: 20px;
    margin-top: 0px;
   
    border-top: 1px solid #f7f1f3; 
  }
  
  .theme-links p {
    margin: 5px 0; 
  }
  
  .theme-links .nav__link {
    text-decoration: none;
    color: rgb(186, 193, 201);
    font-size: 16px; 
   
  }
  
  .theme-links .nav__link:hover {
    text-decoration: underline; 
    color: #be16e0;
  }










 

  
  .address-cont p {
    margin: 5px 0; 
    display:flex; 
    align-items: center;
  }
  
  .address-cont .icon {
    margin-right: 10px; 
  }
  
















 
