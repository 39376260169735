
.carousel-control-prev,
.carousel-control-next {
  width: 5%; 
  color: #fff; 
  font-size: 2rem; 
}


.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5); 
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  color: #ccc;
}
.carousel-caption h3 {
  font-size: 60px; 
  margin-bottom: 233px; 
}



@media screen and (max-width: 768px) {
 
  .carousel-control-prev,
  .carousel-control-next {
    width: 10%; 
    font-size: 1.5rem; 
  }

  .carousel-caption h3 {
    font-size: 80px; 
    margin-bottom: 100px;
  }
}


@media screen and (max-width: 576px) {
 
  .carousel-control-prev,
  .carousel-control-next {
    width: 15%; 
    font-size: 1.2rem; 
  }

  .carousel-caption h3 {
    font-size: 53px; 
    margin-bottom: 273px; 
  }
}
