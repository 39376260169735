*,
*::after,
*::before {
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  font-size: 1.6rem;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: white;
}


.nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 10vh;
  position: fixed;
  z-index: 9999;
  width: 100%;
  background: rgb(13, 43, 71);
}
.nav__brand {
  text-transform: uppercase;
}

.nav__menu {
  display: flex;
  gap: 3rem;
}
.nav__toggler {
  display: none;
}
.nav__toggler div {
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.4rem;
  background-color: #fff;
  transition: 0.3s ease-in;
}
.nav__item .nav__link:hover {
  text-decoration: underline; 
  color: #e03416;
}

@media screen and (max-width: 768px) {
  .nav__toggler {
    display: block;
    cursor: pointer;
    margin-bottom: 25px;
    /* padding: 25px; */
    margin-top: 10px;

  }
 
 .nav__brand{
  margin-bottom: 39px;
 }
  
    .nav__menu {
      position:fixed;
      top: 66px;
      right: 0;
      height: 100%;
      z-index: 9999;
      width: 60vw;
      background: rgb(13, 43, 71);
      background-position: 0%;
      flex-direction: column;
      transform: translateX(100%);
      transition: 0.5s ease-in;
  }
  .img {
    height: 570px;
    width: 100%;
    margin-top: -30px;
}
 
}
/* Active Class */
.nav__active {
  transform: translateX(0%);
  
  
   
    
 
}

/* Toggle Icon Animation */

.toggle .line1 {
  transform: rotate(-45deg) translate(-4px, 5px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-4px, -5px);
}
.icon{
  height: 60px;
  width :30px;

}
/* .img{
  height: 10px;
  width :30px;
} */
.set{
  display: flex;
  flex-direction: column;
  margin-right: 60px;
}
/* .dropdown:hover .dropdown-content {
  display: block;
}
.dropdown .nav_text {
  color: white;
}
/* Dropdown.css */
/* .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 0;
}

.dropdown-item {
  padding: 8px;
} */ 

/* Add any additional styling as needed */
