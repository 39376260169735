.search-bar-container{
    width: 500px;
    padding: 1.42857rem;
    background-color: c;
    display: flex;
   
    align-items: center;
    /* color: ; */
    
    height: 5.71429rem;
    border: 2px solid black;
  
    padding-right: 0px;
    margin-top: 80px;
    margin-left: 50px;
    border-radius: 18px;

}
.input.text{
  color: black;
}
input {
    
    border: none;
   
    font-size: 1.25rem;
    width: 70%;
    background-color: transparent;
  border: none;
  outline: none;
  color: inherit;

   
  }
  
  input:focus {
    outline: none;
  }
  
 

.search-bar-container {
    display: flex;
    align-items: center;
  }
  
   .nearby-me-button {
    padding: 5px;
    height: 55px;
    width: 150px;
    padding-right: 0px;
    border-top-right-radius:15px ;
    border-bottom-right-radius: 15px;
    border: 1px solid rgb(36, 30, 88);
  }
  
  input {
    margin-left: 10px; 
  }
  
 @media(max-width:767px){
  .search-bar-container{
 
    color: rgb(36, 30, 88);
    border: 3px solid rgb(36, 30, 88);
    margin-top: 30px;
    margin-left: 6px;
    border-radius: 15px;
    width: 351px;
  
}
.img{
      width:300px;
      height:300px;
}
 }

  .img{
    width: 100%;
    height: 693px;
    object-fit: cover;
  }
.bold{
  text-shadow: 20px;
  font-size: 40pxd;
}
.text{
  color: black;
  font-size: xxx-large;
  margin-left: 80px;
}
.text-alian{
 color: azure;
 font-size: xx-large;
  margin-left: 80px;
}
.text-alian-h3{
  color: rgb(1, 1, 17);
 font-size: x-large;
  margin-left: 80px;

}
.arrow-icon{
  font-size: 70px;
  margin-left: 80px;
}
@media (max-width: 767px){
input[type='email'], input[type='text'], input[type='tel'] {
    width: 138px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #636161;
    border-radius: 5px;
}
}