.information-card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
 
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: -20px; 
}
 


.item {
 
  align-items:row; 
}
.item h1,
.item h2
 {
  margin: 0; 
  margin-top: 10px;
  color: rgb(1, 11, 14);
  text-align: center;
}

.item svg {
  margin-right: 10px;
}
 .icon-e{
  font-size: xx-large;

  margin-left: 80px;
}
.icon-f{
  font-size: xx-large;
  margin-left: 70px;
 
}
.icon-g{
  font-size: xx-large;
  margin-left: 70px;
  
}
.icon-h{
  font-size: xx-large;
  margin-left: 70px;
 
}
.icon-i{
  font-size: xx-large;
  margin-left: 70px;
  
}
.icon-j{
  font-size: xx-large;
  margin-left: 70px;
 
} 

@media  (max-width: 768px) {
  .item {
    display: flex;

  }
}


