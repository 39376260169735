.img{
    height: 600px;
    width: 1090%;
}
.container {
    display: flex;
    align-items: center;
    
  }
  
  .container img {
    max-width: 250%;
    margin-left: 10px;
    height: 400px;
    margin-bottom: 60px;
    transition: transform 0.3s ease-in-out;
    /* border-radius: 15px; */
  }
  .container img:hover {
    transform: scale(1.1); /* Increase the size of the image on hover */
    border-radius: 20px;
    border-top-right-radius: 130px;
   
    border-bottom-left-radius: 125px;
}
  
  .para-graph {
    
    font-size:large; /* Changed 'font' to 'font-size' */
  
  }
  
  .bodt-text-para {
    margin-left: 400px;
    margin-bottom: 80px;
    margin-top: 40px;
    /* font-style: italic; */
  }
  
 
  @media screen and (max-width: 768px) {
    .container {
      flex-direction: column; 
      align-items: flex-start; /* Align items to the start */
    }
    .text {
      color: black;
      margin-left: 29px;
      font-size: x-large;
  }
  .text-alian {
    color: rgb(226, 57, 15);
    margin-left: 29px;
    font-size: x-large;
   
}
.text-alian-h3{
  color: rgb(5, 17, 17);
  margin-left: 29px;
  font-size: x-large;
}
.arrow-icon {
  font-size: 60px;
  margin-left: 40px;
}
.img {
  height: 100%;
  width: 100%;
}
  
    .container img {
      max-width: 100%; /* Adjust to fit container width */
      height: auto; /* Allow image to maintain aspect ratio */
      margin-left: 0; /* Reset margin */
      margin-bottom: 10px; 
    }
  
    .bodt-text-para {
      margin-left: 0; 
      font-size: 13px;
    }
  }
 
  @media (max-width: 767px){
    input[type='email'], input[type='text'], input[type='tel'] {
        width: 138px;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #636161;
        border-radius: 5px;
    }
  }
   /* --> cARDS CSS --> */

.card-container {
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
}
.card img:hover {
  transform: scale(1.1); 
  transition: transform 0.3s ease; 
}









.card {
  flex: 1;
  margin: 0 10px;
}

.card img {
  max-width: 100%;
  height: auto;
}

.card-text {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
}

.card-text h2 {
  margin-top: 0;
  font-family: fansong;
}

.card-text p {
  margin-bottom: 0;
  font-family: cursive;
} 


@media screen and (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: center; 
    width: 90%;
    margin-left: 15px;
  }

  .card {
    margin: 10px 0; 
  }
  .card img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
   
    box-shadow: 0 0 0 0 transparent;
  }
}










/* In your pgowners.css file */

.nav__brand {
  display: inline-block; /* Ensure the link behaves like a block element */
}

.nav__brand img {
  max-width: 150px; /* Set the maximum width for the image */
  height: auto; /* Maintain aspect ratio */
}



/* // its for footer above css // */

.custom-container {
  display: flex;
  align-items: center; 
}

.image-container {
  flex: 0 0 auto; 
  margin-right: 20px; 
  margin-top:90px;
}

.text-container {
  flex: 1; 
margin-left: 80px;
margin-top: -70px;
}

.text-container h2 {
  margin-top: 5px; 
}

.text-container hr {
  border: none; 
  border-top: 2px solid red; 
  margin: 10px 0; 
}





span>img {
  width: 50px;
  vertical-align: bottom;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-fluid-img{
  max-width: 100%;
  height: auto;
}



.store-logos {
  display: flex;
  
}

.store-logo {
  width: 200px;
  height: 60px;
  margin-top: 20px;
  /* margin-right: 80px; */
}
.store-logo-end{
  width: 200px;
  height: 60px;
  margin-top: 20px;
  margin-left: 30px;
}

/* Common styles */
.mb-0 {
  margin-bottom: 0;
}

/* Normal screen styles */
h1 {
  color: black;
}

/* h2 {
  color: red;
  font-size: 35px;
} */




/* Media query for screens smaller than 768px */
@media screen and (max-width: 768px) {
  .custom-container {
    flex-direction:row; /* Stack items vertically */
    align-items: flex-start; /* Align items to the start of the container */
  }

  .image-container {
    margin-right: 0; /* Remove right margin */
    margin-bottom: 20px; 
  width: 120px;
  }

  .text-container {
    margin-left: 60px; /* Remove left margin */
    margin-top: 0; /* Remove top margin */
    width:100px;
  }

  .store-logos {
    flex-direction:row; 
   justify-content: 20px;
  }

  .store-logo,
  .store-logo-end {
    margin-top: 10px; /* Adjust top margin */
    margin-left: 0; /* Remove left margin */
    width: 50px;
    height: auto;
 
  }

  .store-logo-end {
    margin-top: 10px; /* Remove top margin */
    width: 50px;
    height: 20px;
    margin-right: 20px;
  }
  .text-container hr {
    border: none; 
    border-top: 2px solid red; 
    margin: 10px 0; 
    font-size: small;
  }
  .img-fluid{
      width:100%;
      margin-top: -30px;
      margin-left: 20px;
      height: 100px;
     
  }
 
}

/* Media screen styles */
@media screen and (max-width: 768px) {
  h2 {
      font-size:18px;

  }
  h1{
    font-size: 12px;
  }
}
