.image-with-text-container {
  display: flex;
  align-items: center; /* Vertical alignment */
  margin-bottom: 30px;
}

.image {
  width: 700px; /* Adjust according to your image size */
  height: 500px; /* Maintain aspect ratio */
  margin-right: 20px; 
  margin-top: 60px;
}

.text {
  flex: 1; /* Allow text to grow to fill remaining space */
  /* margin-top: 100px; */
}

.main-paragraph {
  font-size: 16px;
  line-height: 1.5;
  color: #0a0404;
  margin-bottom: 30px;
  margin-right: 70PX;
  margin-top: 30px;
}
.main-text {
  font-size: 35px;
  margin-top: -0px;
  margin-bottom: 20px;
}
.main-paragraph-add {
  font-size: 16px;
  line-height: 1.5;
  color: #0a0404;
  margin-bottom: 0px;
  margin-right: 80PX;
  
}
/* Pgowners.css */
.read-more-button {
  background-color: #082f55;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
}

.read-more-button:hover {
  background-color: #b31343;
}

.messenger-modal{
  background: none;
}


@media screen and (max-width: 768px) {
  .image {
    width: 100%; /* Make the image responsive */
    height: auto; /* Allow the height to adjust based on the width */
    margin: 0; /* Remove margins */
  }
  
  .text {
    margin-top: 30px; /* Adjust spacing */
    margin-bottom: 30px ;
  }
  
  .main-paragraph {
    font-size: 18px; /* Adjust font size */
    margin-right: 0; /* Remove right margin */
  }
  
  .main-text {
    font-size: 30px; /* Adjust font size */
  }
  
  .main-paragraph-add {
    margin-right: 0; /* Remove right margin */
  }
  .image-with-text-container{
    flex-direction: column;
    align-items: center; 
    width: 90%;
    margin-left: 15px;
    
  }
}

.messenger-button {
  background-color: #4267B2; /* Facebook blue */
  
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
}

.messenger-button:hover {
  background-color: #29487d; 
}

.messenger-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.messenger-modal button {
  margin-top: 20px;
}

.messenger-modal button:first-child {
  margin-right: 10px;
}



/* Add your CSS in the appropriate stylesheet */

/* Example of styling the modal */

