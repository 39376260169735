.about{
    color: white;
    /* margin-top: 40px; */
    margin-left: 50px;
}
.body-text{
    margin-bottom: 30px;
    margin-top: 40px;
    font-style: oblique;
    margin-left: 50px;
}
.container {
    display: flex;
}

.body {
    flex: 1;
    padding: 10px;
    margin-top: 40px;
    margin-bottom: 40px;
}


/* .body-text {
    border: 1px solid #000; 
    padding: 10px;
} */
.img {
    height: 570px;
    width: 100%;
}